import { Alert, AlertTitle, Box, Button, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { FORM_API_URL } from '../../constants/config';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const CustomTextField = ({ name, rules, control, ...params }) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error }}) => <TextField
          onChange={onChange}
          value={value}
          color={"white"}
          focused
          fullWidth
          variant="outlined"
          size="small"
          sx={{ input: { color: '#FFF' }}}
          error={!!error}
          helperText={error?.message}
          {...params} />
        } />
    </>
  )
}

function Form({
  labels: { firstName, lastName, email, zipcode, buttonText, invalidMessage, requiredMessage, errorMessage }
}) {
  const { control, handleSubmit } = useForm();
  const [hideForm, setHideForm] = useState(false);
  const [showSubmissionError, setShowSubmissionError] = useState(false);

  const onSubmit = (data) => {
    fetch(FORM_API_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode(data),
    })
      .then(() => {
        setHideForm(true)
        setShowSubmissionError(false)
      })
      .catch((error) => {
        setHideForm(false);
        setShowSubmissionError(true)
      })
  }

  const onError = (errors, e) => {
    console.log('IM AN ERROR')
    console.log(errors, e);
  }

  return (
    <Box sx={{ my: 3 }}>
      { hideForm ?
        (
          <Alert severity="success">
            <AlertTitle>Thank you!</AlertTitle>
            Be on the lookout for the latest updates on our campaign
          </Alert>
        ) :
        (
          <form
            name="newsletter"
            method="post"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <Grid container columns={30} spacing={2} justifyContent="center">
              { showSubmissionError && (
                <Grid item xs={30}>
                  <Alert severity="warning">
                    {errorMessage}
                  </Alert>
                </Grid>
              )}
              <Grid item xs={15}>
                <CustomTextField
                  name="first_name"
                  label={firstName}
                  rules={{ required: requiredMessage|| "First name is required" }}
                  control={control} />
              </Grid>
              <Grid item xs={15}>
                <CustomTextField name="last_name" label={lastName}
                  control={control} />
              </Grid>
              <Grid item xs={30}>
                <CustomTextField name="email" label={email}
                  control={control}
                  rules={{
                    required: requiredMessage || "An email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: invalidMessage || "Please enter a valid email"
                    }
                  }} />
              </Grid>
              <Grid item xs={15}>
                <CustomTextField name="zipcode"
                  label={zipcode}
                  control={control}
                  rules={{
                    required: requiredMessage || "A zipcode is required",
                    minLength: { value: 5, message: invalidMessage || "Please enter valid zipcode" },
                    maxLength: { value: 5, message: invalidMessage || "Please enter valid zipcode" }}} />
              </Grid>
              <Grid item xs={15}>
                <Button size="medium" variant="contained" color="secondary" disableElevation type="submit">
                  {buttonText}
                </Button>
              </Grid>
            </Grid>
          </form>
        )
      }
    </Box>
  )
}

Form.defaultProps = {
  labels: {
    firstName: "First name",
    lastName: "Last name",
    email: "Email",
    zipcode: "Zipcode",
    buttonText: "Subscribe",
    errorMessage: 'There was a network error. Please try again.',
  }
};

export default Form;
